import React from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {SingleStore} from 'storwork-stores'
import Form from "react-jsonschema-form"
import TaskStore from './services/TaskStore'
import SetStore from './services/SetStore'
import Set from './Set'
import PropsInput from './Input'
import edb from "./schemas/edb";

export default props => <Tasks store={SetStore(props.setId)} setId={props.setId} />
const Tasks = observer(props => {
  console.log(props);
  const {records} = props.store

  if (!props.store.loaded) return null
  const CustomInput = PropsInput(props.store)

  return <div>
    Lista reportów:
    {records.map((record, key) => <Record key={key} record={record} setId={props.setId} />)}
    {/*<SetCreator setId={props.setId} />*/}
  </div>
})

const Record = props => {
  const {id, name} = props.record
  return <div><Link to={'/' + props.setId + '/' + id}>
    {name}
  </Link></div>
}

const FormBuilder = observer(props => {
  const value = props.value || "{}"

  return <Form
    schema={edb.schema}
    uiSchema={edb.ui}
    // widgets={widgets}
    formData={JSON.parse(value)}
    onChange={e => props.onChange(JSON.stringify(e.formData))}
    onSubmit={() => {
      props.store.sync(parseInt(props.taskId))
    }}
  />
})

// const SetStore = parentId => new SingleStore({
//   type: 'task',
//   defaultObject: {
//     parent_id: parentId,
//     parent_type: 'App\\Modules\\CRM\\Set',
//     content: '{}'
//   },
//   entities: [{
//     path: '',
//     alwaysSave: true,
//     fields: ['name', 'parent_id', 'parent_type', 'content'],
//     type: 'Task'
//   }]
// })
const SetCreator = props => <Creator store={TaskStore(props.setId)} />

const Creator = props => {
  const Input = PropsInput(props.store)
  return <form onSubmit={e => {
      e.preventDefault()
      props.store.sync(() => {
        window.location.reload()
      })
    }}>
    <Input path='object.name' />
    <Input path='object.content' json='type' options={[
      // {value: '', label: '(none)'},
      {value: 'daily_report', label: 'Dzienny raport'},
      {value: 'uchwala', label: 'Uchwała'}
    ]} />
    <input type="submit" value="Dodaj raport" />
  </form>
}
