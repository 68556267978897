export default {
  "schema": {
    "type": "object",
    "properties": {
      "nrtomu":{
        "type": "string",
        "title": "Numer tomu"
      },
      "kodpocztowy":{
        "type": "string",
        "title": "Kod pocztowy"
      },
      "miejscowosc":{
        "type": "string",
        "title": "Miejscowość"
      },
      "adres":{
        "type": "string",
        "title": "Adres"
      },
      "dznr":{
        "type": "string",
        "title": "Numer działki"
      },
      "obreb":{
        "type": "string",
        "title": "Obręb"
      },
      "symbol":{
        "type": "string",
        "title": "Symbol"
      },
      "numer":{
        "type": "string",
        "title": "Numer"
      },
      "data":{
        "type": "string",
        "title": "Data"
      },
      "present":{
        "type": "string",
        "title": "Present"
      },
      "zewn":{
        "type": "string",
        "title": "Temperatura na zewnątrz"
      },
      "wewn":{
        "type": "string",
        "title": "Temperatur wewnątrz"
      },
      "opady":{
        "type": "string",
        "title": "Opady"
      },
      "wiatr":{
        "type": "string",
        "title": "Wiatr"
      },
      "given":{
        "type": "string",
        "title": "Given"
      },
      "taken":{
        "type": "string",
        "title": "Taken"
      },
      "content":{
        "type": "string",
        "title": "Treść"
      },
      "wydanie":{
        "type": "string",
        "title": "Wydanie"
      },
      "datawydania":{
        "type": "string",
        "title": "Data wydania"
      }
    }
  },
  "ui":{
    "nrtomu":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "kodpocztowy":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "miejscowosc":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "adres":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "dznr":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "obreb":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "symbol":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "numer":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "data":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "present":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "zewn":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "wewn":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "opady":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "wiatr":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "given":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "taken":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "content":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false,
        "multiline": true,
        "rows": 9,
        "fullWidth": true
      }
    },
    "wydanie":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "datawydania":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    }
  }
}
