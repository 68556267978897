import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {StorworkInit} from 'storwork-stores'
import Grid from './Grid'
import Tasks from './Tasks'
import TaskDetails from './TaskDetails'
import Sets from './Sets'
import Set from './Set'
import SetDetails from './SetDetails'
import Navbar from './Navbar'
import {Link} from 'react-router-dom'
import * as C from 'storwork-components'

class App extends Component {
  render() {
    return <StorworkInit loggedIn={<Grid>
      <div area='navbar' size='70px' type='row' style={{gridArea: 'navbar'}}>
        <Navbar />
      </div>
      <div area='task' size='50%' type='row' direction='bottom' style={{gridArea: 'task', overflow: 'auto'}}>
        <Route component={TaskDetails} path='/:setId/:taskId' />
      </div>
      <div area='list' size='50%' type='column' direction='left' style={{gridArea: 'list', overflow: 'auto', padding: '0 5px'}}>
        <Route component={Sets} path='/' />
      </div>
      <div area='main' type='main' style={{gridArea: 'main', overflow: 'auto', padding: '0 5px'}}>
        <Route component={SetDetails} path='/:setId' />
      </div>
    </Grid>} />
  }
}

export default App;
