import React from 'react'
import {Link} from 'react-router-dom'
import Set from './Set'
import Tasks from './Tasks'
import Task from './Task'
import SetStore from "./services/SetStore";
import {observer} from "mobx-react/index";
import * as C from 'storwork-components'
import PropsInput from "./Input";
import TaskStore from "./services/TaskStore";
import {SingleStore} from "storwork-stores";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import axios from "axios/index";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

export default props => {
  return <TaskDetails store={SetStore(props.match.params.setId)} setId={props.match.params.setId} taskId={props.match.params.taskId} />
}

const TaskDetails = observer(props => {

  if(!props.store.object) return null;

  return <C.Box margin={'32px 0'} padding={'0 16px'}>
    <C.Box >
      <Task setId={props.setId} taskId={props.taskId}/>
    </C.Box>
    <C.Box clear />
  </C.Box>
});
