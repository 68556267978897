import {JWT} from "storwork-stores";

export const handleFileUpload = (file, object, store, type = 'file') => {
  // event.preventDefault()
  // this.setState({
  //   uploadActive: true
  // })

  const formData = new FormData()
  formData.append('object_id', object.id)
  formData.append('object_type', object.type)
  formData.append('files[]', file)
  formData.append('type', type)
  //
  // // if (typeof (this.state.uploadedFile) !== 'object') {
  //   // TODO: toast
  //   // return
  // // }
  //
  fetch((process.env.REACT_APP_UPLOAD_URL || 'https://b2b.storwork.pl/api/upload'), {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + JWT.get()
    },
    body: formData
  }).then(response => {
    return response.json()
  }).then(() => {
      // this.setState({
      //   uploadActive: false,
      //   uploadSuccess: true,
      //   uploadedFile: ''
      // })
    }
  ).then(() => {
    // console.log(store);
    store.sync(parseInt(object.id))
    window.location.reload()
  })
}

export const onFileDrop = (files, object, store, type) => {
  handleFileUpload(files[0], object, store, type)
}

export const getLastInformation = (array, type) => {
  const elements = array.filter(el => el.type === type);
  if(elements.length > 0)
    return array.length > 0 ? JSON.parse(elements[elements.length-1].content)[0] : false;
}