import React from 'react'
import {observer} from 'mobx-react'
import {JWT} from 'storwork-stores'
import {SingleStore} from 'storwork-stores'
import axios from 'axios'
import Form from "react-jsonschema-form"
import set from './schemas/set'
import PropsInput from './Input'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {onFileDrop, getLastInformation} from './services/files'
import Dropzone from 'react-dropzone'

export default observer(props => {
  return <Set store={props.store} setId={props.setId} />
})

const Set = observer(props => {
  const { setId } = props
  const set = props.store.object;
  if(props.store.object.indexType === undefined) return null;

  const CustomInput = PropsInput(props.store)

  return <React.Fragment>
    <h1>{set.name}</h1>
    <CustomInput path={'object.content'} json='form'>
      <FormBuilder store={props.store} setId={setId} />
    </CustomInput>
  </React.Fragment>
})

const MyCustomWidget = (props) => {
  return (
    <TextField
      type="text"
      variant={'outlined'}
      fullWidth
      label={props.label}
      value={props.value}
      style={{margin: '8px 2px', display: 'inline', float: 'left', width: '49%'}}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};

const widgets = {
  myCustomWidget: MyCustomWidget
};


const FormBuilder = observer(props => {
  const value = props.value || "{}"
  let formValues = JSON.parse(value);

  const logo = getLastInformation(props.store.object.information, 'edb_logo');
  const signature = getLastInformation(props.store.object.information, 'edb_signatures');

  return <div>
    <div>
      <Form
        schema={set.schema}
        uiSchema={set.ui}
        widgets={widgets}
        formData={formValues}
        onChange={e => props.onChange(JSON.stringify(e.formData))}
        onSubmit={(e) => {
          props.store.sync(parseInt(props.setId))
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }}
      >
      <Button variant="contained" color="primary" type="submit" style={{margin: '16px'}}>Zapisz</Button>
      </Form>
    </div>
    <div style={{padding: '16px'}}>
      <div style={{width: '50%', float: 'left'}}>
        Logo
        <Dropzone
          style={{ overflow: 'auto'}}
          multiple={false}
          onDrop={(e) => onFileDrop(e, {id: parseInt(props.setId), type: 'set'}, props.store, 'edb_logo')}>
          {logo ? <img src={"http://localhost:8000/api/preview/" + logo.download} height="100px" width="auto" alt="Logo"/> : <div>Upuść tutaj lub kliknij, aby dodać pliki...</div>}
        </Dropzone>
      </div>
      <div style={{width: '50%', float: 'left'}}>
        Signatures
        <Dropzone
          style={{ overflow: 'auto'}}
          multiple={false}
          onDrop={(e) => onFileDrop(e, {id: parseInt(props.setId), type: 'set'}, props.store, 'edb_signatures')}>
          {signature ? <img src={"http://localhost:8000/api/preview/" + signature.download} height="100px" width="auto" alt="Signatures"/> : <div>Upuść tutaj lub kliknij, aby dodać pliki...</div>}
        </Dropzone>
      </div>
    </div>
  </div>

})
