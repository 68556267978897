import {ListStore} from 'storwork-stores'

let currentType = false
let currentStore = false
export default (type) => {
  if (type === currentType) return currentStore
  currentStore = new ListStore({
    log: true,
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['name', 'content'],
      type: 'Set',
      multi: false
    }],
    type: 'set',
    size: 60,
    staticFilters: {
      type: type,
      order: 'created_at.timestamp desc'
    }
  })
  return currentStore
}
