import React from 'react'
import { Link } from 'react-router-dom'
import {JWT, Organization} from 'storwork-stores'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

class Navbar extends React.Component {

  handleChange = (e) => {
    const orgId = e.target.value;
    Organization.changeOrganization(orgId);
  }

  render () {
    const tokenData = JWT.remember().claim;

    return <AppBar position="static" color="default">
      <Toolbar>
        <FormControl>
          <Select
            native
            value={tokenData.organization.id}
            onChange={(e) => this.handleChange(e)}
          >
            {tokenData.organizations.map(organization => {
              return <option key={organization.id} value={organization.id}>{organization.name}</option>;
            })}
          </Select>
        </FormControl>
        <Button color="inherit">
          <Link to={'/'}>
            Elektroniczny dziennik
          </Link>
        </Button>
      </Toolbar>
    </AppBar>
  }
}


export default Navbar;