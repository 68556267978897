import {ListStore} from 'storwork-stores'

let currentParent = false
let currentStore = false
export default (parentId) => {
  if (parentId === currentParent) return currentStore
  currentParent = parentId
  currentStore = new ListStore({
    log: true,
    entities: [{
      path: '',
      alwaysSave: true,
      fields: ['name', 'description', 'content', 'extras', 'coc'],
      type: 'Task',
      multi: false
    }],
    type: 'task',
    staticFilters: {
      'parent_id': parentId,
      'parent_type.keyword': 'App\\Modules\\CRM\\Set',
      order: 'created_at.timestamp desc'
    }
  })
  return currentStore
}
