export default {
  "schema": {
    "type": "object",
    "properties": {
      "investor":{
        "type": "string",
        "title": "Inwestor"
      },
      "kierownikbudowy":{
        "type": "string",
        "title": "Kierownik budowy"
      },
      "wykonawcarobot":{
        "type": "string",
        "title": "Wykonawca robót"
      },
      "miejsce":{
        "type": "string",
        "title": "Miejsce"
      },
      "numerdzialki":{
        "type": "string",
        "title": "Numer działki"
      },
      "egzemplarz":{
        "type": "string",
        "title": "Egzemplarz"
      },
      "zmiana":{
        "type": "string",
        "title": "Zmiana"
      },
      "name":{
        "type": "string",
        "title": "Nazwa"
      }
    }
  },
  "ui":{
    "investor":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "kierownikbudowy":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "wykonawcarobot":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "miejsce":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "numerdzialki":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "egzemplarz":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "zmiana":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    },
    "name":{
      "ui:widget": "myCustomWidget",
      "ui:options": {
        "label": false
      }
    }
  }
}
