import React from 'react'
import _ from 'lodash'
import {observer} from 'mobx-react'
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';

const setJson = (json, path, value) => {
  const parsedJson = JSON.parse(json) || {}
  _.set(parsedJson, path, value)
  return JSON.stringify(parsedJson)
}

export default that => observer(props => {
  const {className = '', placeholder = ''} = props
  const { path, json, callback, options } = props
  const pathValue = _.get(that, path)
  const jsonValue = json ? _.get(JSON.parse(pathValue), json) : false
  const onChange = e => {
    const value = e.target ? e.target.value : e
    const newValue = json ? setJson(pathValue, json, value) : value
    _.set(that, path, newValue)
    if (callback && options) callback()
  }
  const value = json ? jsonValue : pathValue
  const insideProps = {onChange, value, className, placeholder}
  if (props.children) return React.cloneElement(React.Children.only(props.children), insideProps)
  return options ? <Select native {...insideProps}>
    {options.map((option, key) => <option key={key} value={option.value}>{option.label || option.value}</option>)}
  </Select> : <Input onBlur={callback || (() => {})} {...insideProps} />
})
