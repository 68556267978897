import React from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {SingleStore} from 'storwork-stores'
import * as C from 'storwork-components'
import SetsStore from './services/SetsStore'
import PropsInput from './Input'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

export default props => <Sets store={SetsStore('daily_report')} />
const Sets = observer(props => {
  const {records} = props.store

  return <C.Box>
    <C.Box padding={'0 0 10px 0'}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>L.p.</TableCell>
            <TableCell>Nazwa miejsca budowy</TableCell>
            <TableCell>Typ</TableCell>
            <TableCell>Utworzony</TableCell>
            <TableCell>Akcje</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {records.map((record, key) => <Record key={key} lp={key+1} record={record} />)}
        </TableBody>
      </Table>
    </C.Box>
    <C.Box left padding={'0 10px'} width={'100%'}>
      <SetCreator listStore={props.store} />
    </C.Box>
  </C.Box>
})

class Record extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render () {
    const props = this.props;
    const {id, name, type, created_at} = props.record;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const content = props.record.content ? JSON.parse(props.record.content).form : '{}';

    return <TableRow key={id}>
      <TableCell>{props.lp}</TableCell>
      <TableCell component="th" scope="row">
        <C.Box onClick={this.handleClick}>
          {name}
        </C.Box>
        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <C.Box>{Object.keys(JSON.parse(content)).length > 0 ? Object.keys(JSON.parse(content)).map((key, field) => {
            return <C.Box key={key}>
              {key}: {JSON.parse(content)[key]}
            </C.Box>
          }) : 'Brak danych na temat budowy' }</C.Box>
        </Popover>
      </TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{created_at.value}</TableCell>
      <TableCell>
        <Link to={'/' + id}>
          Edytuj
        </Link>
      </TableCell>
    </TableRow>
  }
}

const SetStore = () => new SingleStore({
  type: 'set',
  defaultObject: {
    type: 'daily_report'
  },
  entities: [{
    path: '',
    alwaysSave: true,
    fields: ['name', 'type', 'content'],
    type: 'Set'
  }]
})
const SetCreator = props => <Creator store={SetStore()} listStore={props.listStore} />
const Creator = props => {
  const Input = PropsInput(props.store)
  return <form onSubmit={e => {
      e.preventDefault()
      props.store.sync().then(setTimeout(() => {
        props.listStore.loadRecords()
        // window.location.reload();
      }, 1000))

    }}>
    <Input path='object.name' placeholder={'Wpisz nazwę miejsca budowy...'}>
      <TextField
          label="Dodaj nowy..."
          variant="outlined"
        />
    </Input>
    <Button variant="outlined" color="primary" size={'medium'} type="submit" style={{
        margin: 8
      }}>Dodaj</Button>
  </form>
}
