import React from 'react'
import {Link} from 'react-router-dom'
import Set from './Set'
import Tasks from './Tasks'
import SetStore from "./services/SetStore";
import {observer} from "mobx-react/index";
import * as C from 'storwork-components'
import PropsInput from "./Input";
import TaskStore from "./services/TaskStore";
import {SingleStore} from "storwork-stores";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import axios from "axios/index";
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import {getLastInformation} from "./services/files";

export default props => {
  return <SetDetails store={SetStore(props.setId ? props.setId : props.match.params.setId)} listStore={TaskStore(props.setId ? props.setId : props.match.params.setId)} setId={props.setId ? props.setId : props.match.params.setId} taskId={props.taskId ? props.taskId : props.match.params.taskId} />
}

const SetDetails = observer(props => {

      if(props.store.object.tasks === undefined) return null;

      return <C.Box>
        <C.Box padding={'0px 0'}>
          <Set store={props.store} setId={props.setId} />
        </C.Box>
        <C.Box clear />
        <hr/>
        <C.Box>
          <h2>Lista raportów</h2>
          <C.Box>
            {props.listStore.records.map(record => {
              return <Record key={record.id} record={record} set={props.store.object} setId={props.setId} />
            })}
          </C.Box>
        </C.Box>
        <C.Box margin={'32px 0'} padding={'0 16px'}>
          <SetCreator setId={props.setId} listStore={props.listStore} />
        </C.Box>
      </C.Box>
});

const Record = props => {
  const {id, name} = props.record

  const previewLink = process.env.REACT_APP_PREVIEW_URL || 'https://b2b.storwork.pl/api/preview';

  const task = props.record;
  const setContent = JSON.parse(props.set.content).form ? JSON.parse(props.set.content).form : '{}';
  const taskContent = JSON.parse(task.content).form ? JSON.parse(task.content).form : '{}';

  const logoImg = getLastInformation(props.set.information, 'edb_logo');
  const signatureImg = getLastInformation(props.set.information, 'edb_signatures');
  const photos = task.information.filter(el => el.type === 'edb_photos');
  let images = {images: []};
  if(photos.length > 0) {
    photos.map(photo => {
      const content = JSON.parse(photo.content);
      content.map(c => {
        images.images.push(previewLink +"/"+ c.download)
      })
    })
  }

  let logo = [];
  let signature = [];
  if(logoImg) {
    logo = {logo: [
        previewLink +"/"+ logoImg.download
      ]}
  }

  if(signatureImg) {
    signature = {signatures: [
        previewLink +"/"+ signatureImg.download
      ]}
  }

  const templateData = Object.assign({}, JSON.parse(setContent), JSON.parse(taskContent), logo, signature, images);

  return (
    <Paper elevation={2} style={{margin: '8px 0', padding: '8px 8px'}}>
      <C.Box>
        <C.Box style={{display: 'inline-block', whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis'}} title={name}>
          <Link to={'/' + props.setId + '/' + id}>
            {name}
          </Link>
        </C.Box>
        <C.Box right pointer onClick={() => downloadPDF(name, 'daily_report', JSON.stringify(templateData))}>
          &nbsp; PDF
        </C.Box>
      </C.Box>

    </Paper>
  );
}

const NewSetStore = parentId => new SingleStore({
  type: 'task',
  defaultObject: {
    parent_id: parentId,
    parent_type: 'App\\Modules\\CRM\\Set',
    content: '{}'
  },
  entities: [{
    path: '',
    alwaysSave: true,
    fields: ['name', 'parent_id', 'parent_type', 'content'],
    type: 'Task'
  }]
})

const SetCreator = props => <Creator store={NewSetStore(props.setId)} listStore={props.listStore} />

const Creator = observer(props => {
  const Input = PropsInput(props.store)
  return <form onSubmit={e => {
    e.preventDefault()
    props.store.sync().then(setTimeout(() => {
      props.listStore.loadRecords()
      window.location.reload();
    }, 1000))
  }}>
    <Input path='object.name'>
      <TextField
          label="Dodaj nowy raport..."
        />
    </Input>
    <Input path='object.content' json='type'>
      <Select
          style={{margin: '16px 10px 0'}}
            native
          >
          {[
            {value: '', label: 'Wybierz rodzaj raportu'},
            {value: 'report', label: 'Raport'},
            {value: 'uchwala', label: 'Uchwała'}
          ].map(el => <option key={el.value} value={el.value}>{el.label}</option>)}
      </Select>
    </Input>
    <Button variant="outlined" color="primary" size={'small'} type="submit">Dodaj</Button>
  </form>
})

const downloadPDF = (name, type, vars) => {
  // console.log(vars);
  axios.post('https://templates.storwork.pl/get', {
    name: type,
    vars: JSON.parse(vars)
  }, {
    responseType: 'blob'
  }).then(response => {
    window.download(response.data, name + '.pdf', 'application/pdf');
  })
}
