import React from 'react'
import {Link} from 'react-router-dom'
import {observer} from 'mobx-react'
import {SingleStore} from 'storwork-stores'
import axios from 'axios'
import Form from "react-jsonschema-form"

import report from './schemas/report'
import edb from './schemas/edb'
import TaskStore from './services/TaskStore'
import TasksStore from './services/TasksStore'
import PropsInput from './Input'
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core/styles';
import SetStore from "./services/SetStore";
import SetDetails from './SetDetails'
import TextField from '@material-ui/core/TextField';
import * as C from 'storwork-components'
import set from "./schemas/set";
import Button from '@material-ui/core/Button';
import {onFileDrop} from './services/files'
import Dropzone from 'react-dropzone'

export default props => <Task store={TaskStore(props.setId)} singleStore={TasksStore(props.taskId)} setId={props.setId} taskId={props.taskId} />

const Task = observer(props => {
  const { taskId } = props
  const taskIndex = props.store.records.findIndex(el => el.id == taskId)
  // const task = props.store.records.find(el => el.id == taskId)
  if (!props.store.loaded) return null
  const CustomInput = PropsInput(props.store)
  // const set = task.parent_set;
  // console.log(set);
  // const setContent = set.content && JSON.parse(set.content).form ? JSON.parse(set.content).form : '{}';
  // const taskContent = set.content && JSON.parse(task.content).form ? JSON.parse(task.content).form : '{}';
  //
  // const templateData = Object.assign({}, JSON.parse(setContent), JSON.parse(taskContent));

  return <React.Fragment>
    <CustomInput path={'records.' + taskIndex + '.content'} json='form'>
      <FormBuilder store={props.store} taskId={taskId} />
    </CustomInput>
  </React.Fragment>
})


const MyCustomWidget = (props) => {
  const {multiline, rows, fullWidth} = props.options;
  return (
    <TextField
      type="text"
      multiline={multiline}
      rows={rows}
      variant={'outlined'}
      fullWidth
      label={props.label}
      value={props.value}
      style={{margin: '8px 2px', display: 'inline', float: 'left', width: '49%'}}
      onChange={(event) => props.onChange(event.target.value)}
    />
  );
};

const widgets = {
  myCustomWidget: MyCustomWidget
};

const FormBuilder = observer(props => {
  const value = props.value || "{}"

  const task = props.store.records.find(el => el.id === parseInt(props.taskId));
  const photos = task.information.filter(el => el.type === 'edb_photos');

  return <div>
    <h1>{task.name}</h1>
    <Form
        schema={edb.schema}
        uiSchema={edb.ui}
        widgets={widgets}
        formData={JSON.parse(value)}
        onChange={e => props.onChange(JSON.stringify(e.formData))}
        onSubmit={(e) => {
          props.store.sync(parseInt(props.taskId))
          setTimeout(() => {
            // window.location.reload()
          }, 1000)
        }}
      >
      <Button variant="contained" color="primary" type="submit" style={{margin: '16px'}}>Zapisz</Button>
    </Form>
    <div style={{padding: '16px'}}>
      <div style={{width: '50%', float: 'left'}}>
        <h2>Zdjęcia</h2>
        <Dropzone
          style={{ overflow: 'auto'}}
          multiple={false}
          onDrop={(e) => onFileDrop(e, {id: parseInt(props.taskId), type: 'task'}, props.store, 'edb_photos')}>
          {photos.map(photo => {
            const content = JSON.parse(photo.content);
            return content.map((c, k) => {
              return <div key={k} style={{display: 'inline', margin: '0px 5px 5px 0px'}}>
                <img src={"http://localhost:8000/api/preview/" + c.download} height="100px" width="auto" alt="Photos"/>
              </div>
            })
          })}
          <br/>
          {photos.length > 0 ? <Button variant="contained" color="primary">Dodaj więcej zdjęć</Button> : <Button variant="contained" color="primary">Kliknij, aby dodać pliki...</Button>}
        </Dropzone>
      </div>
    </div>
  </div>
})
